// Be sure you take the time to create a nice 404 page.
import * as React from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import Image404 from "../images/ETBUFB.jpg";

const wrapper = {
  padding: "3rem",
  marginTop: "3rem",
};

const NotFoundPage = () => {
  return (
    <>
      <Helmet
        title="404 - Not found!"
        titleTemplate={`%s - Symmetry Sports`}
        meta={[
          {
            name: `description`,
            content: "The page that you are looking for could not be found.",
          },
        ]}
      ></Helmet>

      <main className="py-5">
        <section id="404-not-found" className="page-banner">
          <img src={Image404} className="page-banner-cover" alt="404" />
        </section>

        <div className="container py-5" style={wrapper}>
          <h1>404 Not Found</h1>
          <p>The page that you are looking for could not be found.</p>
          <Link className="btn btn-primary  btn-hover-icon" to="/">
            Go home
          </Link>
        </div>
      </main>
    </>
  );
};

export default NotFoundPage;
